<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="addNotification" ref="form">
        <v-card>
          <v-card-title>Add Notification</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    counter="125"
                    label="Notification Message"
                    hide-details="auto"
                    v-model="form.message"
                    :error-messages="form.$getError('message')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import Form from '@/utils/form'
// import Notification from '@/models/Notification'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: this.value,
      form: new Form({
        message: null
      })
    }
  },

  methods: {
    addNotification() {
      this.form.$busy = true

      this.$api
        .post(`settings/notification/motivation/message`, this.form.$data())
        .then(() => {
          this.$emit('added')
          this.closeDialog()
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    closeDialog() {
      this.dialog = false
      this.form.$clearErrors()

      this.form = new Form({
        message: null
      })
    }
  },

  watch: {
    dialog(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.dialog = val
    }
  }
}
</script>
