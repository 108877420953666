<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Motivation</v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-app-bar>

    <div class="col-md-4 col-sm-12 col-xs-12" v-if="!loading">
      <v-form
        @submit.prevent="submitMotivationForm"
        ref="motivationForm"
        :disabled="form.$busy"
      >
        <v-menu
          ref="menu"
          v-model="motivationTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="form.motivation_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              class="mt-2"
              v-model="form.motivation_time"
              label="Notification Time"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="motivationTimeMenu"
            v-model="form.motivation_time"
            full-width
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="motivationTimeMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(form.motivation_time)"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-menu>

        <label class="text-field-label">Day of Week</label>
        <v-btn-toggle mandatory class="mt-2" v-model="form.motivation_day">
          <v-btn
            v-for="(day, index) in days"
            :key="index"
            :disabled="form.$busy"
          >
            {{ day }}
          </v-btn>
        </v-btn-toggle>

        <v-card class="mt-8" :disabled="form.$busy">
          <v-toolbar flat outlined>
            <v-toolbar-title>Notification Messages</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="addNotificationDialog = true" icon>
              <v-icon>{{ icons.add }}</v-icon>
            </v-btn>
          </v-toolbar>
          <template v-if="form.motivation_messages.length > 0">
            <draggable
              :list="form.motivation_messages"
              :sort="true"
              @change="orderMessages"
            >
              <div
                v-for="(message, index) in form.motivation_messages"
                :key="message.id"
              >
                <MotivationListItem
                  :value="message"
                  :week="index"
                  @deleted="getSettings"
                ></MotivationListItem>
              </div>
            </draggable>
          </template>
          <div class="pa-4 text-caption" v-else>No messages</div>
        </v-card>

        <div class="d-flex py-5 justify-end">
          <v-btn
            :loading="form.$busy"
            type="submit"
            color="primary"
            width="150px"
            depressed
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </div>

    <AddNotificationDialog
      v-model="addNotificationDialog"
      @added="getSettings"
    ></AddNotificationDialog>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiPlus } from '@mdi/js'
import draggable from 'vuedraggable'
import MotivationListItem from './components/MotivationListItem'
import AddNotificationDialog from './components/AddNotificationDialog'
import Form from '@/utils/form'

export default {
  name: 'ReassessmentNotificationPage',

  mixins: [ControlsMixin],

  components: {
    draggable,
    AppBarNavIcon,
    MotivationListItem,
    AddNotificationDialog
  },

  created() {
    this.getSettings()
  },

  data() {
    return {
      loading: false,
      form: new Form({
        motivation_day: null,
        motivation_time: null,
        motivation_messages: []
      }),
      addNotificationDialog: false,
      motivationTimeMenu: false,
      motivationTime: null,
      motivationDay: null,
      days: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      icons: {
        add: mdiPlus
      },
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    async getSettings() {
      this.loading = true
      this.$api
        .get(`settings/notification`)
        .then(response => {
          this.form = new Form(response.data.data)
        })
        .finally(() => (this.loading = false))
    },

    submitMotivationForm() {
      this.form.$clearErrors()
      this.form.$busy = true
      this.$api
        .post(`settings/notification/motivation`, {
          time: this.form.motivation_time,
          day: this.form.motivation_day
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .then(() => this.showSnackbar('Successfully updated!', 'success'))
        .finally(() => (this.form.$busy = false))
    },

    orderMessages() {
      this.$api.post(`settings/notification/motivation/message/set-order`, {
        motivation_messages: this.form.motivation_messages
      })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>
