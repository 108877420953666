<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="editNotification" ref="form">
        <v-card>
          <v-card-title>Edit Notification</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    counter="125"
                    label="Notification Message"
                    hide-details="auto"
                    v-model="form.message"
                    :error-messages="form.$getError('message')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import Form from '@/utils/form'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: Object
    }
  },

  data() {
    return {
      dialog: this.value,
      form: new Form({
        message: this.message.text
      })
    }
  },

  methods: {
    editNotification() {
      this.form.$busy = true
      this.$api
        .post(
          `settings/notification/motivation/message/${this.message.id}`,
          this.form.$data()
        )
        .then(() => {
          this.$emit('updated', this.form.message)
          this.closeDialog()
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    closeDialog() {
      this.dialog = false
      this.form.$clearErrors()
    }
  },

  watch: {
    dialog(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.dialog = val
    }
  }
}
</script>
