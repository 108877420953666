<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Week {{ week + 1 }}</v-list-item-subtitle>
          {{ message.text }}
        </v-list-item-content>
        <v-list-item-action>
          <div>
            <v-btn
              :loading="loading"
              @click="editNotificationDialog = true"
              :class="{ 'visibility-hidden': !hover }"
              icon
            >
              <v-icon>{{ icons.edit }}</v-icon>
            </v-btn>
            <v-btn
              :loading="loading"
              @click="deleteConfirmDialog = true"
              :class="{ 'visibility-hidden': !hover }"
              icon
            >
              <v-icon color="red lighten-3">{{ icons.delete }}</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-hover>
    <v-divider></v-divider>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <EditNotificationDialog
      v-model="editNotificationDialog"
      :message="message"
      @updated="updateMessage"
    ></EditNotificationDialog>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete message"
      message="Are you sure you want to delete this message?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteItem"
    ></ConfirmModal>
  </div>
</template>

<script>
import { mdiPencil, mdiTrashCanOutline } from '@mdi/js'
import ConfirmModal from '@/components/modals/ConfirmModal'
import EditNotificationDialog from './EditNotificationDialog'

export default {
  props: {
    value: {
      type: Object
    },
    week: {
      type: Number
    }
  },

  components: {
    ConfirmModal,
    EditNotificationDialog
  },

  data() {
    return {
      loading: false,
      message: this.value,
      deleteConfirmDialog: false,
      editNotificationDialog: false,
      icons: {
        edit: mdiPencil,
        delete: mdiTrashCanOutline
      },
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    deleteItem() {
      this.loading = true
      this.$api
        .delete(`settings/notification/motivation/message/${this.message.id}`)
        .then(() => {
          this.$emit('deleted')
        })
        .finally(() => (this.loading = false))
    },

    updateMessage(message) {
      this.message.text = message
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>

<style scoped>
.visibility-hidden {
  visibility: hidden;
}
</style>
